<template>
  <ion-page>
     <syaberu-menu/>
     <ion-content fullscreen>
       <ion-label>
         <h3 class="ion-padding ion-margin">{{msg}}</h3>
         <h2><a :href="paymentURL" v-if="show">レシートはこちら</a></h2>
        </ion-label>
       <img class="payment" src="https://cdn.gifted-pocket.com/images/payment.png" />
     </ion-content> 
  </ion-page>
</template>
<script>

const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
const SQUARE_PAYMENT = FUNCTIONS_HOST_NAME + "/squarePayment/createPayment";
const UPDATE_PAYMENT_PROGRESS_URL = FUNCTIONS_HOST_NAME + "/payment/updateProgress";
const PAYMENT_SEQUENCE_URL = FUNCTIONS_HOST_NAME + "/paymentSequence";
import { IonPage,
IonLabel,
IonContent
} from '@ionic/vue';
import { v4 as uuidv4 } from 'uuid';
import  SyaberuMenu from './SyaberuMenu.vue';
export default  {
  name: 'PaymentProcedure',
  components: { 
    IonPage,
    IonLabel,
    IonContent,
    SyaberuMenu
   },
  data(){
    return {
      msg : "処理中...",
      show : false,
      paymentURL : null,
    }
  },
  async mounted(){
     this.$root.startLoading();
     const payment = this.$store.state.payment;
     if(payment == null){
       this.$router.push("/home");
     }
     const params = {
       "token" : payment.token,
       "idKey" : uuidv4(),
       "amount" : payment.amount,
       'tenantId' : this.$store.state.activeUser.tenantId,
       "note" : this.makeNote(payment.invoices)
     };
     //支払い処理
     let result = await this.axios.post(SQUARE_PAYMENT,params);
     console.log(result);
     const paymentResult = result.data;
     //TSUMUGUのdb更新
     this.updateProgress(payment.invoices,paymentResult);
     //slackに通知する
     this.notifySlack(payment.invoices);
     this.$root.endLoading();
     //ここで結果の通知
     if(paymentResult.errors){
       let msg = '';
       paymentResult.errors.forEach((error,index) => {
         msg += `[${index}] ${error.code}:${error.detail}  `;
       });
       this.msg = `精算中にエラーが発生しました。管理者にお問い合わせください。エラーメッセージ:{${msg}}`;
     }else{
       this.msg = `精算が完了しました`;
       this.show = true;
       this.paymentURL = paymentResult.payment.receiptUrl;
     }
  },
  methods : {
      updateProgress : async function(invoices,paymentResult){
       for (const invoice of invoices) {
          // 進捗終了 = overlay解除
          invoice.progress = false;
          if(paymentResult.errors){
            paymentResult.errors.forEach(error => {
               if(!invoice.errorCode){
                 invoice.errorCode = '';
               }
               invoice.errorCode += error.code + ",";
            });
            invoice.status = "NG";
          }else{
            invoice.paid = true;
            invoice.transactionId = paymentResult.payment.id;
            invoice.status = "OK";
          }
          // フラグを寝かせる
          let response = await this.axios.put(UPDATE_PAYMENT_PROGRESS_URL, invoice);
          console.log(response.data);
          invoice.YYYYMM = invoice.year + invoice.month;
          // overlayの解除
          response = this.axios.post(PAYMENT_SEQUENCE_URL,invoice);
          console.log(response.data);
          // キャッシュの削除
          this.$store.commit('setPayment',null);
        }
    },
  notifySlack : async function(message){
    const SLACK_POST_MESSAGE_URL = "https://us-central1-attendancerecorder-admin.cloudfunctions.net/slack_api/v1";
    // [{"purchasedProduct":300,"meal":200,"year":"2021","extend":100,"userId":"U_000015","tenantId":"test1","paid":true,"sum":500,"month":"11","progress":false,"transactionId":"R6Dxe8I8VIaqN9HdAl51X5bijoaZY","status":"OK"}]
    let msg = '';
    if(message){
      message.forEach(element => {
        let targetInvoice = element.year + element.month;
        msg += `[SYABERU]:{status => ${element.status}, paymentId(transactionId) => ${element.transactionId}, targetInvoice => ${targetInvoice}, userId => ${element.userId}, sum => ${element.sum}}¥n`;
      });
    }else{
      //do nothing: alert
      msg = `Something wrong happens while sending Slack message from Syaberu#PaymentProcedure`;
    }
    return this.axios.post(SLACK_POST_MESSAGE_URL,{
      msg: JSON.stringify(msg),
      channel: "#payment-info"
    });
  },
  makeNote(invoices){
    let userName = this.$store.state.activeUser.userName;
    let note = '[SYABERU(mobile)]:';
    invoices.forEach(invoice => {
      note += `${invoice.year}年${invoice.month}月分、`;
    });
    note += ` 対象ユーザ: ${userName}`;
    return note;
  }
  }
}
</script>
<style>
.payment {
  position: absolute;
  top: 350px;
}
</style>